import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactform"

import Form from "../components/contactform"

const KontaktPage = () => (
  <Layout>
    <SEO title="Kontakt - Kancelaria Prawna RIO" />
    <section className="top content-page">
      <div className="wrap">
        <h4>Kontakt</h4>
        <h2>Kancelaria Prawna RIO</h2>
      </div>
    </section>
    <section className="mapa">
      <div className="wrap">
        <div className="left-contact-column">
          <a
            target="blank"
            href="https://www.google.com/maps/place/Kancelaria+Prawna+RIO+-+upad%C5%82o%C5%9B%C4%87+konsumencka/@54.5197814,18.5376953,15z/data=!4m2!3m1!1s0x0:0x641bf7c619cb742c?sa=X&ved=2ahUKEwi80aaDi-PlAhXCpIsKHYPoDS8Q_BIwcHoECGAQCA"
          ></a>
        </div>
        <div className="right-contact column">
          <ContactForm />
        </div>
      </div>
    </section>
  </Layout>
)

export default KontaktPage
